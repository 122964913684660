import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faGifts, faPlus } from "@fortawesome/free-solid-svg-icons";
library.add(faGifts, faPlus);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Axios from "axios";
Axios.defaults.baseURL = "https://api.ternakdankafe.online/public/api/v1";
// Axios.defaults.baseURL = "http://localhost:8000/api/v1";
// Axios.defaults.withCredentials = false;
Axios.defaults.headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  Accept: "application/json",
};

import "@fortawesome/fontawesome-free/css/all.min.css"; //Font awesome dalam bentuk font
import "@/assets/main.scss";

const app = createApp(App);
app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
