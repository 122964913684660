import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login/login_main.vue"),
  },
  {
    path: "/",
    name: "login",
    component: () => import("../views/Login/login_main.vue"),
  },
  {
    path: "/pick-telur",
    name: "PickTelur",
    component: () => import("../views/PickTelur/pick_main.vue"),
    meta: {
      requiresAuth: true,
      title: "Pick Telur",
    },
  },
  {
    path: "/input-makan",
    name: "InputMakan",
    component: () => import("../views/InputMakan/pakan_main.vue"),
    meta: {
      requiresAuth: true,
      title: "Input Makan",
    },
  },

  {
    path: "/laporan-pick",
    name: "LaporanPick",
    component: () => import("../views/LaporanPick/laporan_main.vue"),
    meta: {
      requiresAuth: true,
      title: "Laporan Transfer Telur",
    },
  },

  {
    path: "/laporan-makan",
    name: "LaporanMakan",
    component: () =>
      import("../views/LaporanLangsirMakan/laporan_makan_main.vue"),
    meta: {
      requiresAuth: true,
      title: "Laporan Langsir Makan",
    },
  },

  {
    path: "/user-area",
    name: "UserArea",
    component: () => import("../views/UserArea/user_main.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !localStorage.token) {
    next("/login");
  } else if (to.meta.requiresUnauth && !!localStorage.token) {
    next("/PickTelur");
  } else {
    next();
  }
});

export default router;
