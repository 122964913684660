<template>
  <div>
    <router-view @handleLogin="handleLogin" @handleLogout="handleLogout" />
    <CurvedBottomNavigation
      :options="options"
      v-model="selected"
      v-if="loaded"
    />
  </div>
</template>

<script>
import { CurvedBottomNavigation } from "bottom-navigation-vue";
import "bottom-navigation-vue/dist/style.css";
import { ref, onMounted } from "vue";
import router from "@/router";
import UserService from "@/services/user_services";

export default {
  name: "App-Main",
  components: {
    CurvedBottomNavigation,
  },
  setup() {
    const selected = ref(0);
    const options = ref([
      {
        id: 0,
        icon: "fas fa-truck-arrow-right",
        title: "Pick Telur",
        path: { name: "PickTelur" },
      },
      {
        id: 1,
        icon: "fas fa-bowl-food",
        title: "Input Makan",
        path: { name: "InputMakan" },
      },
      {
        id: 2,
        icon: "fas fa-book",
        title: "Laporan",
        childs: [
          {
            id: 201,
            icon: "fas fa-egg",
            title: "Telur",
            path: { name: "LaporanPick" },
          },
          {
            id: 202,
            icon: "fas fa-bowl-food",
            title: "Makan",
            path: { name: "LaporanMakan" },
          },
        ],
      },
      // {
      //   id: 2,
      //   icon: "fas fa-book",
      //   title: "Laporan Transfer",
      //   path: { name: "LaporanPick" },
      // },
      // {
      //   id: 3,
      //   icon: "fas fa-book",
      //   title: "Laporan Makan",
      //   path: { name: "LaporanMakan" },
      // },
      {
        id: 4,
        icon: "fas fa-user-large",
        title: "User Area",
        path: { name: "UserArea" },
      },
      // {
      //   id: 5,
      //   icon: "fas fa-plus",
      //   title: "Setting",
      //   path: { name: "UserArea", query: { key: "value" } },
      //   childs: [{ id: 301, icon: "fas fa-ticket-alt", title: "Tickets" }],
      // },
    ]);

    const loaded = ref(false);
    onMounted(() => {
      if (localStorage.token) {
        loaded.value = true;
        router.push({ name: "PickTelur" });
      }
    });
    const handleLogin = () => {
      loaded.value = true;
    };

    const handleLogout = () => {
      localStorage.removeItem("token");
      UserService.clearDefaultHeader();
      router.push({ name: "login" });
      loaded.value = false;
    };

    return { options, selected, loaded, handleLogout, handleLogin };
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || "Chicken Farm";
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.smaller-text {
  font-size: 0.9em;
}
</style>
