import Axios from "axios";
const RESOURCE_NAME = "/user";

export default {
  async signIn(data) {
    return Axios.post(`${RESOURCE_NAME}/login`, data);
  },
  register(data) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.post(`${RESOURCE_NAME}/register`, data, { headers });
  },
  setDefaultHeader(token) {
    Axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  },
  clearDefaultHeader() {
    Axios.defaults.headers.common = { Authorization: null };
  },
  updateUser(data) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.put(`${RESOURCE_NAME}/update`, data, { headers });
  },
  getOwnData() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.get(`${RESOURCE_NAME}/own`, { headers });
  },
  getAll() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.get(`${RESOURCE_NAME}/all`, {
      headers,
    });
  },
  getOne(asetId) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.get(`${RESOURCE_NAME}/${asetId}`, { headers });
  },
  createData(data) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.post(`${RESOURCE_NAME}/new`, data, { headers });
  },
  updateCertainUser(id, data) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.put(`${RESOURCE_NAME}/${id}`, data, { headers });
  },
  logout() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.post(`${RESOURCE_NAME}/logout`, {}, { headers });
  },
  async getProfile() {
    const headers = {
      Authorization: `Bearer ${await localStorage.token}`,
    };
    return Axios.get(`${RESOURCE_NAME}/profile`, { headers });
  },
  updateOthers(data, id) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.put(`${RESOURCE_NAME}/update-single/${id}`, data, { headers });
  },
  updateOwn(data) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.put(`${RESOURCE_NAME}/update-own`, data, { headers });
  },
  deleteUser(id) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.delete(`${RESOURCE_NAME}/delete/${id}`, { headers });
  },
};
